import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a select form input
 *
 * @category EDS Components
 */
class EDSSelect extends React.Component {
  static displayName = 'EDSSelect';
  /**
   * @type {EDSSelect}
   * @property {String} [addAppend] - The icon "name" string for adding an assistive visual and trigger button to the end of the element
   * @property {Function} [addOnClick] - The function to fire off when the prepended icon is clicked.
   * @property {String} [addPrepend] - The string for adding assistive text to the beginning of the element
   * @property {String} [assistiveText] - The string for adding assistive text below the input element
   * @property {Number} [heightModifier] - The number corresponding to preset CSS classes for setting a pixel height on the element
   * @property {String} [label] - The human readable label for the select
   * @property {String} [modifiers] - CSS classes for modifying the component styling
   * @property {Boolean} [multiSelect] The boolean that determines if the select should render with multi-selectable options
   * @property {String} [name] - The name of the input field
   * @property {Function} [onChange] - The action to be triggered when the input changes
   * @property {ArrayOf} [options] The list of options to be rendered inside the select element
   * @property {String} [[options].optionName] - The visible name/label for the option
   * @property {Boolean} [[options].selected] - The boolean to determine if the option should have the "selected" attribute
   * @property {Number | String} [[options].value] - The value of the option - either a string or number
   * @property {String} [value] - The value of the input
   *  @property {Boolean} [disabled] - The boolean flag that indicates whether the onChange event should fire
   **/
  static propTypes = {
    addAppend: PropTypes.string,
    addOnClick: PropTypes.func,
    addPrepend: PropTypes.string,
    assistiveText: PropTypes.string,
    heightModifier: PropTypes.number,
    label: PropTypes.string,
    modifiers: PropTypes.string,
    multiSelect: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        optionName: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    multiSelect: false,
  };

  constructor(props) {
    super(props);

    this._handleChangeSingle = this._handleChangeSingle.bind(this);
    this._handleChangeMultiple = this._handleChangeMultiple.bind(this);
    this._renderOptions = this._renderOptions.bind(this);
    this._renderAddOnClick = this._renderAddOnClick.bind(this);
    this._renderAddPrepend = this._renderAddPrepend.bind(this);

    this.state = { value: [] };
  }

  _handleChangeSingle(e) {
    this.setState({ value: e.target.value });
    const { onChange } = this.props;
    if (onChange) onChange(e);
  }

  _handleChangeMultiple(e) {
    let newValue = e.target.value;
    let stateValue = this.state.value;

    stateValue.indexOf(newValue) === -1
      ? stateValue.push(newValue)
      : stateValue.length === 1
      ? (stateValue = [])
      : stateValue.splice(stateValue.indexOf(newValue), 1);

    this.setState({ value: stateValue });
  }

  _renderOptions() {
    const { options = [] } = this.props;

    return options.map(o => {
      const { optionName, value } = o;
      return (
        <option value={value} key={value}>
          {optionName}
        </option>
      );
    });
  }

  _renderAddPrepend() {
    const { addPrepend } = this.props;

    return (
      <div
        className={
          'eds-input_#prepend eds-input_.eds-input-addon eds-input-addon eds-input-addon.prepend eds-input-addon.gray'
        }
      >
        <span
          className={
            'material-icons eds-input-addon_#content eds-input-addon_#content.text'
          }
        >
          {addPrepend}
        </span>
      </div>
    );
  }

  _renderAddOnClick() {
    const { addAppend, addOnClick } = this.props;

    return (
      <div
        className={
          'eds-input_#append eds-input_.eds-input-addon eds-input-addon eds-input-addon.append'
        }
      >
        <button
          className={
            'material-icons eds-input-addon_#content eds-input-addon_#content.icon eds-input-addon_.eds-link eds-link'
          }
          onClick={addOnClick}
        >
          {addAppend}
        </button>
      </div>
    );
  }

  render() {
    const {
      addAppend,
      addOnClick,
      addPrepend,
      assistiveText,
      heightModifier,
      label,
      modifiers,
      multiSelect,
      name,
      options = [],
      value,
      disabled,
    } = this.props;
    const typeClass = multiSelect ? 'multiselect' : 'dropdown';
    const heightClass =
      multiSelect && heightModifier ? `h-${heightModifier}` : '';
    const size = options.length;
    const stateInputClass = !disabled
      ? this._renderAddPrepend()
      : 'eds-input.disabled';

    if (options) {
      return (
        <label className={`eds-field ${modifiers}`} htmlFor={name}>
          <div className={'eds-field_#label'}>{label}</div>
          <div
            className={`eds-field_#control eds-field_.eds-input eds-input ${heightClass} ${
              disabled ? 'eds-field_#label.disabled' : ''
            } `}
          >
            {addPrepend ? this._renderAddPrepend() : undefined}
            <select
              className={`eds-input_#input eds-input_#input.${typeClass} eds-input_#input.${disabled}`}
              id={name}
              multiple={!!multiSelect}
              name={name}
              onChange={
                multiSelect
                  ? this._handleChangeMultiple
                  : this._handleChangeSingle
              }
              size={multiSelect ? size : undefined}
              value={value}
            >
              {this._renderOptions()}
            </select>
            {addAppend && addOnClick ? this._renderAddOnClick() : undefined}
          </div>
          {assistiveText ? (
            <div className={'eds-field_#assistive-text'}>{assistiveText}</div>
          ) : (
            ''
          )}
        </label>
      );
    }
  }
}

export const EDS_Select = EDSSelect;

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a breadcrumb
 *
 * @category EDS Components
 */
class EDSBreadcrumb extends React.Component {
    static displayName = 'EDSBreadcrumb';
    /**
     * @type {EDSBreadcrumb}
     * @property {String} [background] - The background for the breadcrumb
     * @property {Arrayof} [links] - The list of breadcrumb links
     * @property {Function} [onChange] - The action to be triggered when the input changes
     * @property {String} [modifiers] - CSS classes for modifying breadcrumb styling
     * @property {String} [linkModifiers] - CSS classes for modifying breadcrumb link styling
     * @property {ElementType} [LinkComponentType] - The component type for links
     * @property {Object} [linkProps] - The props for the Link components
     * @property {String} [homeIcon] - The name for home icon
     * @property {String} [homeSlug] - The slug for home link
     **/
    static propTypes = {
        background: PropTypes.oneOf(["white", "gray"]),
        links: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string
        })),
        onChange: PropTypes.func,
        modifiers: PropTypes.string,
        linkModifiers: PropTypes.string,
        LinkComponentType: PropTypes.elementType,
        linkProps: PropTypes.object,
        homeIcon: PropTypes.string,
        homeSlug: PropTypes.string,
    };
    
    static defaultProps = {
        background: "white",
        modifiers: "",
        linkModifiers: "",
        homeIcon: "home",
        LinkComponentType: "a",
    };

    constructor(props){
        super(props);
        this._renderLink = this._renderLink.bind(this);
        this._renderLinks = this._renderLinks.bind(this);
        this._onChangeAction = this._onChangeAction.bind(this);
    }
    
    _onChangeAction = (link) => {
        const {onChange} = this.props;
        if(onChange){
            return (ev) => {
                ev.preventDefault();
                onChange(link);
            }
        }
    }

    _renderLink(link, firstItem, selected) {
        const {linkModifiers, LinkComponentType, linkProps, homeIcon} = this.props;

        const selectedClass = selected ? "eds-link.selected" : "";
        const renderIconClassName = firstItem ? 'eds-breadcrumbs_.icon material-icons' : "";
        const className = `eds-breadcrumbs_.part eds-link ${renderIconClassName} ${selectedClass} ${linkModifiers}`;
        
        const hrefPropName = LinkComponentType === 'a' ? 'href' : 'to';

        // local classes will override externallay provided className field
        // linkModifiers may be used to provide classes externally
        let linkPropsLocal = {
            ...linkProps,
            className,
            onClick: this._onChangeAction(link),
            [hrefPropName]: link.slug,
        }

        return (
            <Fragment key={link.name}>
                {!firstItem &&  <span className="eds-breadcrumbs_.part">/</span>}
                <LinkComponentType {...linkPropsLocal}>
                    {!firstItem && link.name}
                    {firstItem && homeIcon}
                </LinkComponentType>
            </Fragment>
        );
    }

    _renderLinks() {
        const {links = []} = this.props;
        return links.map( (link, i) => this._renderLink(link, false, i === links.length - 1));
    }
    
    render() {
        const {background, modifiers, homeSlug} = this.props;
        const backgroundGrayClass = background === "gray" ? "bg-gray-100" : "";
        const homeLink = {
            name:"home", 
            slug:homeSlug
        };

        return (
            <div className={`eds-breadcrumbs ${backgroundGrayClass} ${modifiers}`}>
                {this._renderLink(homeLink, true, false)}
                {this._renderLinks()}
            </div>
        );
    }
}

export const EDS_Breadcrumb = EDSBreadcrumb;
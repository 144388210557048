import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a button
 *
 * @category EDS Components
 */
class EDSButton extends React.Component {
    static displayName = 'EDSButton';
    /**
     * @type {EDSButton}button
     * @property {String} ariaLabel - The label for alternate text for accessibility
     * @property {String} buttonText - The readable text for the button
     * @property {Boolean} disabled - The Boolean flag indicating where the button is disabled or not
     * @property {String} iconName - The name of the optional icon
     * @property {String} modifiers - CSS classes for modifying button styling
     * @property {Function} onClick - The action to be called by the onClick event
     * @property {String} role - The string defining the role for accessibility
     **/
    static propTypes = {
        ariaLabel: PropTypes.string,
        buttonText: PropTypes.string,
        disabled: PropTypes.bool,
        iconName:  PropTypes.string,
        modifiers: PropTypes.string,
        onClick: PropTypes.func,
        role: PropTypes.string
    };

    static defaultProps = {
        ariaLabel: 'Click Me',
        disabled: false,
        modifiers: 'eds-button.primary',
        role: 'button'
    };

    constructor(props){
        super(props);

        this._renderChildren = this._renderChildren.bind(this);

    }

    _renderChildren = () => {
        const {buttonText, iconName} = this.props;
        const renderText = buttonText ? <span className={'eds-button_#label'}>{buttonText}</span>: null;

        if(iconName){
            return (
                <React.Fragment>
                    <i className={`eds-button_#icon material-icons`} role={"img"} aria-label={iconName}>{iconName}</i>
                    {renderText}
                </React.Fragment>
            );
        }
    };

    render() {
        const {ariaLabel, buttonText, disabled, iconName, modifiers, onClick, role} = this.props;
        const disabledAttribute = disabled ? 'disabled' : '';
        const onClickAction = disabled ? null : onClick;
        return (
            <button
                aria-label={ariaLabel}
                className={`eds-button ${modifiers}`}
                disabled={disabledAttribute}
                onClick={onClickAction}
                role={role}>
                {iconName ? this._renderChildren() : buttonText}
            </button>
        );
    }
}

export const EDS_Button = EDSButton;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a text input
 *
 * @category EDS Components
 */
class EDSTextBox extends React.Component {
  static displayName = 'EDSTextBox';
  /**
   * @type {EDSTextBox}
   * @property {Object} [addPrepend] - The object for adding a helpful clue to the beginning of the element
   * @property {String} [[addPrepend].prependType] - The type of clue to render
   * @property {String} [[addPrepend].prependValue] - The visible name/label for the option
   * @property {String} [assistiveText] - The string for adding assistive text below the input element
   * @property {Boolean} [disabled] - The boolean flag that indicates whether the onChange event should fire
   * @property {Boolean} [hasError] - The boolean flag that indicates if the input has an error
   * @property {Boolean} [hasWarning] - The boolean flag that indicates if the input has a warning
   * @property {String} [label] - The human readable label for the input
   * @property {String} [labelModifier] - Prop to add CSS classes for modifying the display of the label text
   * @property {String} [modifiers] - CSS classes for modifying field styling
   * @property {String} [name] - The name of the input field
   * @property {Function} [onBlur] - Event to fire when the user blurs off of the field.
   * @property {Function} [onChange] - The action to be called when the input is changed
   * @property {Function} [onFocus] - Event to fire when the input field is focused.
   * @property {String} [placeHolder] - The placeholder text for the input
   * @property {Boolean} [readOnly] - The Boolean flag indicating whether the field should be read only
   * @property {String} [type] - The string specifying the type of input the field should expect as to render the proper keyboard
   * @property {String || Number} [value] - The value of the input – either a string (text/email) or number
   * @property {String | Number} [min] - Specifies the minimum value allowed and works same as normal html
   * @property {String | Number} [max] - Specifies the maximum value allowed and works same as normal html
   **/
  static propTypes = {
    addPrepend: PropTypes.shape({
      prependType: PropTypes.oneOf(['text', 'icon']),
      prependValue: PropTypes.string,
    }),
    assistiveText: PropTypes.string,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    hasWarning: PropTypes.bool,
    label: PropTypes.string,
    labelModifier: PropTypes.string,
    modifiers: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeHolder: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'email', 'number', 'password']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    hasError: false,
    hasWarning: false,
    readOnly: false,
    type: 'text',
    value: undefined,
  };

  constructor(props) {
    super(props);

    this._onBlur = this._onBlur.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._renderAddPrepend = this._renderAddPrepend.bind(this);
    this._renderAssistiveText = this._renderAssistiveText.bind(this);
    this._renderStateClass = this._renderStateClass.bind(this);
  }

  _onBlur() {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur();
    }
  }

  _onFocus() {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus();
    }
  }

  _renderAddPrepend() {
    const { addPrepend } = this.props;
    const { prependType, prependValue } = addPrepend;

    return (
      <div
        className={
          'eds-input_#prepend eds-input_.eds-input-addon eds-input-addon eds-input-addon.prepend eds-input-addon.gray'
        }
      >
        <span
          className={`material-icons eds-input-addon_#content eds-input-addon_#content.${prependType}`}
        >
          {prependValue}
        </span>
      </div>
    );
  }

  _renderAssistiveText() {
    const { assistiveText, hasError, hasWarning } = this.props;
    const baseClassName = 'eds-field_#assistive-text';

    return (
      <div
        className={`${baseClassName} ${
          hasError
            ? baseClassName + '.error'
            : hasWarning
            ? baseClassName + '.warning'
            : ''
        }`}
      >
        {assistiveText}
      </div>
    );
  }

  _renderStateClass() {
    const { hasError, hasWarning } = this.props;
    const baseClassName = 'eds-input';

    return hasError
      ? baseClassName + '.error'
      : hasWarning
      ? baseClassName + '.warning'
      : '';
  }

  render() {
    const {
      addPrepend,
      assistiveText,
      disabled,
      label,
      labelModifier,
      modifiers,
      name,
      onChange,
      placeHolder,
      readOnly,
      type,
      value,
      min,
      max,
    } = this.props;
    const stateInputClass = !disabled
      ? this._renderStateClass()
      : 'eds-input.disabled';
    const onChangeAction = !readOnly ? onChange : () => false;

    return (
      <label
        className={`eds-field ${modifiers ? modifiers : ''}`}
        htmlFor={name}
      >
        <div
          className={`eds-field_#label ${
            disabled ? 'eds-field_#label.disabled' : ''
          } ${labelModifier}`}
        >
          {label}
        </div>
        <div
          className={`eds-field_#control eds-field_.eds-input eds-input ${stateInputClass}`}
        >
          {addPrepend && this._renderAddPrepend()}
          <input
            className={'eds-input_#input'}
            id={name}
            type={type}
            disabled={disabled}
            name={name}
            onBlur={this._onBlur}
            onChange={onChangeAction}
            onFocus={this._onFocus}
            placeholder={placeHolder}
            value={value}
            min={min}
            max={max}
          />
        </div>
        {assistiveText && this._renderAssistiveText()}
      </label>
    );
  }
}

export const EDS_TextBox = EDSTextBox;

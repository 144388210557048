import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a progress bar
 *
 * @category EDS Components
 */
class EDSProgress extends React.Component {
    static displayName = 'EDSProgress';
    /**
     * @type {EDSProgress}
     * @property {Number} [baselineMax] - The specified numeric baseline maximum of the progress bar
     * @property {Number} [baselineVal] - The current numeric baseline value of the progress bar
     * @property {String} [color] - The base color of the progress bar
     * @property {Number} [primaryMax] - The specified numeric primaryMaximum of the progress bar
     * @property {Number} [primaryVal] - The current numeric value of the progress bar
     * @property {Number} [widthModifier] - The number corresponding to predefined CSS classes for setting a pixel width on the element
     **/
    static propTypes = {
        baselineMax: PropTypes.number,
        baselineVal: PropTypes.number,
        color: PropTypes.oneOf(['purple','magenta']),
        primaryMax: PropTypes.number,
        primaryVal: PropTypes.number,
        widthModifier: PropTypes.number,
    };

    static defaultProps = {
        color: 'purple',
        widthModifier: 192
    };

    render() {
        const {baselineMax, baselineVal, color, primaryMax, primaryVal, widthModifier} = this.props;

        return (
            <div className={`eds-progress eds-progress.${color} w-${widthModifier}`}>
                <progress value={primaryVal} max={primaryMax} aria-label={"Value"} className={"eds-progress_.progress eds-progress_#primary"}>&nbsp;</progress>
                {baselineMax || baselineVal
                    ? <progress value={baselineVal} max={baselineMax} aria-label={"Baseline value"} className={"eds-progress_.progress eds-progress_#baseline"}>&nbsp;</progress>
                    : undefined
                }
            </div>
        );
    }
}

export const EDS_Progress = EDSProgress;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a Menu Item
 *
 * @category EDS Components
 */
class EDSMenuItem extends React.Component {
  static displayName = 'EDSMenuItem';
  /**
   * @type {EDSMenuItem}
   * @property {String} menuItemText - The readable text for the menu item
   * @property {Boolean} disabled - The Boolean flag indicating where the menu item is disabled or not
   * @property {String} modifiers - CSS classes for modifying menut item styling
   * @property {Function} onClick - The action to be called by the onClick event
   * @property {String} iconName - The name of the optional icon
   * @property {String} motif - string that the theme of the sidebar
   **/

  static propTypes = {
    menuItemText: PropTypes.string,
    disabled: PropTypes.bool,
    modifiers: PropTypes.string,
    onClick: PropTypes.func,
    iconName: PropTypes.string,
    motif: PropTypes.oneOf(['basic', 'gray', 'blue', 'raspberry']),
  };

  static defaultProps = {
    menuItemText: 'Menu Item',
    disabled: false,
    modifiers: '',
    motif: 'basic',
  };

  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick(e) {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  }

  _renderChildren = () => {
    const { menuItemText, iconName } = this.props;
    const renderText = menuItemText ? (
      <span className="eds-menu-item_#label">{menuItemText}</span>
    ) : null;

    if (iconName) {
      return (
        <React.Fragment>
          <i
            className={`eds-menu-item_#icon material-icons`}
            role={'img'}
            aria-label={iconName}
          >
            {iconName}
          </i>
          {renderText}
        </React.Fragment>
      );
    }
  };

  render() {
    const { menuItemText, disabled, modifiers, iconName, motif } = this.props;
    const disabledAttribute = disabled ? 'disabled' : '';
    const onClickAction = disabled ? null : this._onClick;

    return (
      <a
        className={`eds-menu-item eds-menu-item.${motif} ${
          modifiers ? modifiers : ''
        }`}
        disabled={disabledAttribute}
        onClick={onClickAction}
      >
        {iconName ? this._renderChildren() : menuItemText}
      </a>
    );
  }
}

export const EDS_MenuItem = EDSMenuItem;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a Sidebar
 *
 * @category EDS Components
 */
class EDSSidebar extends React.Component {
  static displayName = 'EDSSidebar';
  /**
   * @type {EDSSidebar}
   * @property {Object} style - raw css for modifying side bar styling
   * @property {ArrayOf} sections - array of section components that will be use to build the content of the sidebar
   * @property {String} motif - string that the theme of the sidebar
   * @property {String} collapseText - string that represent the text of the verbiage of the sidebar collapse menu link and aria-label
   * @property {String} expandText - string that represent the text of the verbiage of the sidebar expand aria-label
   * @property {Boolean} open - The Boolean flag indicating whether the sidebar is opened or closed
   **/

  static propTypes = {
    motif: PropTypes.oneOf(['basic', 'gray', 'blue', 'raspberry']),
    style: PropTypes.object,
    collapseText: PropTypes.string,
    expandText: PropTypes.string,
    open: PropTypes.bool,
  };

  static defaultProps = {
    motif: 'basic',
    collapseText: 'Collapse Menu',
    expandText: 'Expand Menu',
    open: true,
  };

  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
    this.makeCollapsable = this.makeCollapsable.bind(this);

    this.state = {
      toggled: props.open ? 'open' : 'closed',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      toggled: nextProps.open ? 'open' : 'closed',
    });
  }

  _onClick(e) {
    e.preventDefault();

    this.setState({
      toggled: this.state.toggled === 'closed' ? 'open' : 'closed',
    });
  }

  makeCollapsable = () => {
    const { collapseText, expandText, motif } = this.props;
    const { toggled } = this.state;

    return (
      <div className="eds-sidebar_#controls" style={{ cursor: 'pointer' }}>
        <a
          onClick={this._onClick}
          className={`eds-sidebar_.eds-sidebar-toggle eds-sidebar.${toggled}_.eds-sidebar-toggle eds-sidebar-toggle eds-sidebar-toggle.${motif}`}
        >
          {toggled === 'open' && (
            <React.Fragment>
              <i
                className="eds-sidebar-toggle_#icon material-icons eds-sidebar_.when-open"
                aria-hidden="true"
                aria-label={collapseText}
              >
                first_page
              </i>
              <span className="eds-sidebar-toggle_#label eds-sidebar_.when-open">
                {collapseText}
              </span>
            </React.Fragment>
          )}

          {toggled === 'closed' && (
            <i
              className="eds-sidebar-toggle_#icon material-icons eds-sidebar_.when-closed"
              aria-hidden="true"
              aria-label={expandText}
            >
              last_page
            </i>
          )}
        </a>
      </div>
    );
  };

  render() {
    const { children, style, motif } = this.props;

    const { toggled } = this.state;

    return (
      <div
        className={`eds-sidebar eds-sidebar.${toggled} eds-sidebar.${motif}`}
        style={style}
      >
        <div className="eds-sidebar_#content">{children}</div>
        {this.makeCollapsable()}
      </div>
    );
  }
}

export const EDS_Sidebar = EDSSidebar;

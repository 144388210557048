import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a nav item
 *
 * @category EDS Components
 */
class EDSNavItem extends React.Component {
    static displayName = 'EDSNavItem';
    /**
     * @type {EDSNavItem}
     * @property {String} [modifiers] - CSS classes for modifying nav item styling
     * @property {String} [text] - The readable text for the NavItem
     * @property {String} [icon] - The name of the icon for the NavItem
     * @property {Boolean} [popover] - The boolean flag that indicates whether the popover icon should be visible
     * @property {Boolean} [ariaHasPopup] - The boolean flag that indicates whether the nav item has popup
     * @property {Boolean} [selected] - The boolean flag that indicates whether the nav item is selected
     * @property {ElementType} [LinkComponentType] - The component type to render the nav item inner link
     * @property {String} [avatarInitials] - The text for avatar inititals
     **/
    static propTypes = {
        modifiers: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.string,
        popover: PropTypes.bool,
        ariaHasPopup: PropTypes.bool,
        selected: PropTypes.bool,
        LinkComponentType: PropTypes.elementType,
        slug: PropTypes.string,
        avatarInitials: PropTypes.string,
    };
    
    static defaultProps = {
        popover: false,
        icon: "",
        text: "",
        LinkComponentType: "a",
    };

    constructor(props){
        super(props);
    }

    render() {
        const {text, icon, popover, ariaHasPopup, selected, modifiers, LinkComponentType, slug, avatarInitials, ...rest} = this.props;
        
        const classCommon = 'eds-primary-header_.eds-primary-nav-item eds-primary-nav-item eds-primary-nav-item.eds-link eds-link';
        const classPopover = popover ? 'eds-primary-nav-item.dropdown' : '';
        const classSelected= selected ? 'eds-link.selected' : '';
        const className = `${classCommon} ${classPopover} ${classSelected} ${modifiers}`;

        const hrefPropName = LinkComponentType === 'a' ? 'href' : 'to';
        const linkProps = {
            [hrefPropName]: slug,
            ...rest
        };

        return (
            <LinkComponentType className={className} {...linkProps} aria-haspopup={ariaHasPopup ? "true": "false"}>
                {icon && <i className="eds-primary-nav-item_#icon material-icons" aria-hidden="true">{icon}</i>}
                {text && <span className="eds-primary-nav-item_#label">{text}</span>}
                {avatarInitials && 
                    <div className="eds-primary-nav-item_.eds-avatar eds-avatar eds-avatar.sm eds-avatar.blue" 
                        aria-hidden="true" 
                        data-initials={avatarInitials}
                        />
                    }
            </LinkComponentType>
        );
    }
}

export const EDS_NavItem = EDSNavItem;
import React from 'react';
import PropTypes from 'prop-types';
import {EDS_TableHeader} from './EDS_TableHeader.jsx';
import {EDS_TableCell} from './EDS_TableCell.jsx';

/**
 * Component responsible for rendering a table
 *
 * @category EDS Components
 */
class EDSTable extends React.Component {
    static displayName = 'EDSTable';
    /**
     * @type {EDSTable}
     * @property (Array) [columnHeaders] - The list of column names to be mapped to the <th/> element
     * @property {Boolean} [compact] - The boolean flag that indicates if the table should render with the 'compact' class modifier
     * @property {String} [modifiers] - The prop for passing additional css class modifier(s), when needed.
     * @property {Object} [styleAttr] - The object to inject inline styles into the table.
     * @property (ArrayOf) [tableData] - The array of values to map to the table rows
     **/
    static propTypes = {
        columnHeaders: PropTypes.arrayOf(PropTypes.any),
        compact: PropTypes.bool,
        modifiers: PropTypes.string,
        styleAttr: PropTypes.object,
        tableData: PropTypes.arrayOf(PropTypes.any)
    };

    constructor(props){
        super(props);

        this._renderColumnHeaders = this._renderColumnHeaders.bind(this);
        this._renderTableRows = this._renderTableRows.bind(this);

    }

    _renderColumnHeaders = () => {
        const {columnHeaders = []} = this.props;

        return columnHeaders.map((val = {}, index) => {
            return <EDS_TableHeader alignText={val.align} key={index}>{val.content}</EDS_TableHeader>
        })
    };

    _renderTableRows = () => {
        const {tableData = []} = this.props;

        return tableData.map((data, index) => {

            const row = Object.values(data);

            return <tr className={"eds-table_.row"} key={`tr-${index}`}>
                {row.map((val = {}, index) => {
                    return <EDS_TableCell alignText={val.align} key={index}>{val.content}</EDS_TableCell>
                })}
            </tr>
        })
    };


    render() {
        const {compact = false, modifiers = '', styleAttr} = this.props;
        const makeCompact = compact ? `eds-table.compact` : '';

        return (
            <table className={`eds-table ${makeCompact} ${modifiers}`} style={styleAttr}>
                <thead className={"eds-table_#header"}>
                    <tr className={"eds-table_.row eds-table_.row.header"}>
                        {this._renderColumnHeaders()}
                    </tr>
                </thead>
                <tbody className={"eds-table_#content"}>
                    {this._renderTableRows()}
                </tbody>
            </table>
        );
    }
}

export const EDS_Table = EDSTable;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a radio
 *
 * @category EDS Components
 */
class EDSRadio extends React.Component {
    static displayName = 'EDSRadio';
    /**
     * @type {EDSRadio}
     * @property {Boolean} [checked] - The boolean flag that indicates whether the input is checked
     * @property {Boolean} [disabled] - The boolean flag that indicates whether the onChange event should fire
     * @property {String} [id] - The unique id for the input element and label
     * @property {String} [label] - The human readable label for the radio
     * @property {String} [modifiers] - CSS classes for modifying radio styling
     * @property {String} [name] - The name of the input field
     * @property {Function} [onChange] - The action to be triggered when the input changes
     * @property {Number} [tabIndex] - The tab index to be applied to the radio group
     * @property {String} [value] - The value of the checked box
     **/
    static propTypes = {
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        groupValue: PropTypes.string,
        label: PropTypes.string,
        modifiers: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
        tabIndex: PropTypes.number,
        value: PropTypes.string
    };

    static defaultProps = {
        tabIndex: 0,
        value: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    render() {
        const {checked, label, modifiers, disabled, name, onChange, tabIndex} = this.props;
        const {value} = this.state;
        const _id = `${name}-${value}`;
        const onChangeAction = !disabled ? onChange : () => false;

        return (
            <label className={`eds-radio ${modifiers}`} htmlFor={_id}>
                <input
                    className={'eds-radio_#input'}
                    type={'radio'}
                    checked={checked}
                    disabled={disabled}
                    id={_id}
                    name={name}
                    onChange={onChangeAction}
                    tabIndex={tabIndex}
                    value={value}
                />
                <span className={'eds-radio_#label'}>{label}</span>
            </label>
        );
    }
}

export const EDS_Radio = EDSRadio;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a table
 *
 * @category EDS Components
 */
class EDSTableCell extends React.Component {
    static displayName = 'EDSTableCell';
    /**
     * @type {EDSTableCell}
     * @property {String} [alignText] - The string to define the text-align CSS class of the element
     * @property {*} [children] - The content to render inside the table cell
     **/
    static propTypes = {
        alignText: PropTypes.oneOf(['left','right']),
        children: PropTypes.any
    };

    render() {
        const {alignText = '', children} = this.props;
        const alignClass = alignText && alignText !== 'left' ? `text-${alignText}` : '';

        return (
            <td className={`eds-table_.cell ${alignClass}`}>
                {children}
            </td>
        );
    }
}

export const EDS_TableCell = EDSTableCell;
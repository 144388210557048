import React from 'react';
import PropTypes from 'prop-types';
import { EDS_Button } from "./EDS_Button.jsx";

/**
 * Component responsible for rendering an Modal component
 *
 * @category EDS Components
 */
class EDSModal extends React.Component {
    static displayName = 'Modal';

    /**
     * @type {EDSModal}
     * @property {*} [children] - any elements to be rendered within the Modal component
     * @property {*} [content] - prop for passing content to be rendered, rather than children
     * @property {String} [title] - the verbiage of the modal heading
     * @property {String} [triggerText] - the verbiage of the modal trigger button
     */
    static propTypes = {
        children: PropTypes.any,
        content: PropTypes.any,
        title: PropTypes.string,
        triggerText: PropTypes.string
    };

    constructor (props) {
        super(props);

        this.state = {
            showModal: false
        };

        this._handleToggleModal = this._handleToggleModal.bind(this);
        this._handleCloseModal = this._handleCloseModal.bind(this);
    }

    _handleToggleModal() {
        this.setState({ showModal: !this.state.showModal });
    };

    _handleCloseModal(e) {
        if(this.node.contains(e.target)) {
            return
        }
        this.setState({ showModal: false });
    };

    render() {
        const {children, content = children, title, triggerText} = this.props;
        const {showModal} = this.state;
        const openClass = showModal ? 'eds-modal.open' : '';

        return <React.Fragment>
            <EDS_Button
                buttonText={triggerText}
                onClick={this._handleToggleModal}
            />
            <section className={`eds-modal ${openClass}`} onClick={this._handleCloseModal}>
                <div className="eds-modal_#container" ref={node => this.node = node}>
                    <button className={"eds-modal_#close eds-modal_.eds-link eds-link material-icons"} onClick={this._handleToggleModal}>close</button>
                    <header className="eds-modal_#header">
                        <h1 className="eds-modal_.title">{title}</h1>
                    </header>
                    <p className="eds-modal_#content">
                        {content}
                    </p>
                    <footer className="eds-modal_#footer">
                        <EDS_Button
                            buttonText={'Dismiss'}
                            modifiers={'eds-button eds-button.basic'}
                            onClick={this._handleToggleModal}
                        />
                    </footer>
                </div>
            </section>
        </React.Fragment>;
    }

}

export const EDS_Modal = EDSModal;
import React from 'react';
import PropTypes from 'prop-types';
import ExperianLogo from '../../assets/ExperianLogo.svg';
/**
 * Component responsible for rendering a PrimaryHeader
 *
 * @category EDS Components
 */
class EDSPrimaryHeader extends React.Component {
  static displayName = 'EDSPrimaryHeader';
  /**
   * @type {EDSPrimaryHeader}
   * @property {String} [title] - The text for the title
   * @property {Boolean} [logo] - The boolean flag that indicates whether the logo should be visible
   * @property {String} [slugLogo] - The desination url slug to go to when logo is clicked on
   * @property {Function} [onClickLogo] - The action to be called when logo is clicked on. It will be called if slugLogo is not given
   * @property {String} [modifiers] - CSS classes for modifying PrimaryHeader styling
   * @property {ElementType} [LogoComponentType] - The custom component type to render the logo image
   * @property {String} [logoImageSrc] - The text for the source image of the logo
   * @property {ElementType} [LogoLinkComponentType] - The component type to render the link that wraps the logo image
   **/
  static propTypes = {
    title: PropTypes.string,
    logo: PropTypes.bool,
    slugLogo: PropTypes.string,
    onClickLogo: PropTypes.func,
    modifiers: PropTypes.string,
    LogoComponentType: PropTypes.elementType,
    logoImageSrc: PropTypes.string,
    LogoLinkComponentType: PropTypes.elementType,
  };

  static defaultProps = {
    logo: false,
    title: '',
    LogoLinkComponentType: 'a',
    logoImageSrc: ExperianLogo,
  };

  constructor(props) {
    super(props);
    this._renderLogo = this._renderLogo.bind(this);
    this._renderLogoImage = this._renderLogoImage.bind(this);
  }

  _renderLogoImage() {
    const { logoImageSrc } = this.props;
    return (
      <img
        src={logoImageSrc}
        alt="Experian Logo"
        style={{
          height: '59.375%',
          transform: 'translate(0, 2px)',
        }}
      />
    );
  }

  _renderLogo() {
    const {
      LogoComponentType,
      LogoLinkComponentType,
      slugLogo,
      onClickLogo,
      logoImageSrc,
    } = this.props;

    const hrefPropName = LogoLinkComponentType === 'a' ? 'href' : 'to';
    const linkProps = {
      [hrefPropName]: slugLogo,
      onClick: onClickLogo,
      'data-testid': 'primary-header-logo',
    };

    return (
      <LogoLinkComponentType
        className="eds-primary-header_#logo"
        {...linkProps}
      >
        {LogoComponentType && <LogoComponentType />}
        {!LogoComponentType && this._renderLogoImage()}
      </LogoLinkComponentType>
    );
  }

  render() {
    const { title, logo, modifiers, children } = this.props;
    const classNames = `eds-primary-header ${modifiers}`;
    return (
      <header className={classNames} style={{ display: 'flex' }}>
        {logo && this._renderLogo()}
        {title && <h1 className="eds-primary-header_#title">{title}</h1>}
        {children}
      </header>
    );
  }
}

export const EDS_PrimaryHeader = EDSPrimaryHeader;

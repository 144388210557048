import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering an Accordion component
 *
 * @category EDS Components
 */
class EDSAccordion extends React.Component {
  static displayName = 'Accordion';

  /**
   * @type {EDSAccordion}
   * @property {ArrayOf} [accordionItems] - The array of individual accordion elements
   * @property {String | Element} [[accordionItems].summary] - The trigger/toggle for the individual accordion
   * @property {String} [[accordionItems].modifiers] - The css modifier for the outer container
   * @property {String} [[accordionItems].modifiersContainerOpen] - The css modifier for the container of the individual accordion
   * @property {String | Element} [[accordionItems].content] - The content inside the collapsible portion of the accordion
   * @property {Function} [onToggle] - A callback to fire whenever an AccordionItem toggle button is clicked.
   */
  static propTypes = {
    accordionItems: PropTypes.arrayOf(
      PropTypes.shape({
        summary: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        modifiers: PropTypes.string,
        modifiersContainerOpen: PropTypes.string,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      })
    ),
    onToggle: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this._renderItems = this._renderItems.bind(this);
  }

  _renderItems = () => {
    const { accordionItems = [], onToggle } = this.props;

    return accordionItems.map((item = [], index) => {
      return (
        <AccordionItem
          content={item.content}
          index={index}
          key={index}
          onToggle={onToggle}
          modifiersContainerOpen={item.modifiersContainerOpen}
          summary={item.summary}
          isOpen={item.isOpen}
        />
      );
    });
  };

  render() {
    const { accordionItems, modifiers } = this.props;

    if (accordionItems) {
      return (
        <div className={`eds-accordion ${modifiers}`}>
          {this._renderItems()}
        </div>
      );
    }

    return null;
  }
}

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);

    this._handleToggle = this._handleToggle.bind(this);

    this.state = {
      open: props.isOpen,
    };
  }

  _handleToggle = () => {
    const { index, summary, onToggle } = this.props;

    this.setState(prevState => ({ open: !prevState.open }));

    if (onToggle) onToggle(index, summary);
  };

  render() {
    const { content, summary, modifiersContainerOpen } = this.props;
    const { open } = this.state;
    const openClass = open ? 'eds-details.open' : '';

    if (content) {
      return (
        <div className={`eds-accordion_.eds-details eds-details ${openClass}`}>
          <button
            className={'eds-details_#summary'}
            onClick={this._handleToggle}
          >
            <span>{summary}</span>
          </button>
          <div
            className={`eds-details_#container ${
              open ? modifiersContainerOpen || '' : ''
            }`}
          >
            <div className={'eds-details_#content'}>{content}</div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export const EDS_Accordion = EDSAccordion;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a password input
 *
 * @category EDS Components
 */
class EDSPasswordField extends React.Component {
    static displayName = 'EDSPasswordField';
    /**
     * @type {EDSPasswordField}
     * @property {String} [assistiveText] - The string for adding assistive text below the input element
     * @property {Boolean} [disabled] - The boolean flag that indicates whether the onChange event should fire
     * @property {Boolean} [hasError] - The boolean flag that indicates if the input has an error
     * @property {Boolean} [hasWarning] - The boolean flag that indicates if the input has a warning
     * @property {String} [label] - The human readable label for the input
     * @property {String} [modifiers] - CSS classes for modifying field styling
     * @property {String} [name] - The name of the input field
     * @property {Function} [onBlur] - Event to fire when the user blurs off of the field.
     * @property {Function} [onChange] - The action to be called when the input is changed
     * @property {function} [onFocus] - Event to fire when the input field is focused.
     * @property {Boolean} [readOnly] - The Boolean flag indicating whether the field should be read only
     * @property {String} [type] - The string specifying the type of input - used in this case to show/hide the input value
     * @property {String} [value] - The value of the input
     **/
    static propTypes = {
        assistiveText: PropTypes.string,
        disabled: PropTypes.bool,
        hasError: PropTypes.bool,
        hasWarning: PropTypes.bool,
        label: PropTypes.string,
        modifiers: PropTypes.string,
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        readOnly: PropTypes.bool,
        type: PropTypes.string,
        value: PropTypes.string
    };

    static defaultProps = {
        hasError: false,
        hasWarning: false,
        readOnly: false,
        type: 'password',
        value: undefined
    };

    constructor(props){
        super(props);

        this._onBlur = this._onBlur.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._renderAssistiveText = this._renderAssistiveText.bind(this);
        this._renderStateClass = this._renderStateClass.bind(this);
        this._renderToggle = this._renderToggle.bind(this);
        this._toggleInputType = this._toggleInputType.bind(this);

        this.state = {
            inputType: 'password'
        }

    }

    _onBlur(){
        const {onBlur} = this.props;

        if(onBlur){
            onBlur();
        }

    }

    _onFocus(){
        const {onFocus} = this.props;

        if(onFocus){
            onFocus();
        }

    }

    _renderAssistiveText() {
        const {assistiveText, hasError, hasWarning} = this.props;
        const baseClassName = 'eds-field_#assistive-text';

        return (
            <div className={`${baseClassName} ${hasError ? baseClassName + '.error' : hasWarning ? baseClassName + '.warning' : ''}`}>
                {assistiveText}
            </div>
        )
    }

    _renderStateClass() {
        const {hasError, hasWarning} = this.props;
        const baseClassName = 'eds-input';

        return hasError ? baseClassName + '.error' : hasWarning ? baseClassName + '.warning' : '';
    }

    _renderToggle = () => {
        const {inputType} = this.state;

        return (
            <div className="eds-input_#append eds-input_.eds-input-addon eds-input-addon eds-input-addon.append">
                <button
                    aria-label={inputType === 'password' ? 'Show Password' : 'Hide Password'}
                    className={"material-icons eds-input-addon_#content eds-input-addon_#content.icon eds-input-addon_.eds-link eds-link"}
                    onClick={this._toggleInputType}>
                    {inputType === 'password' ? 'visibility_on': 'visibility_off'}
                </button>
            </div>
        );
    }

    _toggleInputType(){

        this.setState({
            inputType: this.state.inputType === 'text' ? 'password' : 'text'
        })
    }

    render() {
        const {assistiveText, disabled, label, modifiers, name, onChange, readOnly, value} = this.props;
        const stateInputClass = !disabled ? this._renderStateClass() : 'eds-input.disabled';
        const onChangeAction = !readOnly ? onChange : () => false;
        const {inputType} = this.state;

        return (
            <div className={`eds-field ${modifiers ? modifiers : ''}`}>
                <label className={`eds-field_#label ${disabled ? 'eds-field_#label.disabled' : ''}`} htmlFor={name}>{label}</label>
                <div className={`eds-field_#control eds-field_.eds-input eds-input ${stateInputClass}`}>
                    <input
                        className={'eds-input_#input'}
                        id={name}
                        type={inputType}
                        disabled={disabled}
                        name={name}
                        onBlur={this._onBlur()}
                        onChange={onChangeAction}
                        onFocus={this._onFocus()}
                        value={value}
                    />
                    {this._renderToggle()}
                </div>
                {assistiveText && this._renderAssistiveText()}
            </div>
        );
    }
}

export const EDS_PasswordField = EDSPasswordField;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a nav section
 *
 * @category EDS Components
 */
class EDSNavSection extends React.Component {
    static displayName = 'EDSNavSection';
    /**
     * @type {EDSNavSection}
     * @property {String} [modifiers] - CSS classes for modifying nav section styling
     * @property {String} [ariaLabel] - The text for aria label
     **/
    static propTypes = {
        modifiers: PropTypes.string,
        ariaLabel: PropTypes.string,
    };
    
    static defaultProps = {
        ariaLabel: "Header"
    }

    render() {
        const {children, modifiers, ariaLabel} = this.props;
        return (
            <nav className={`eds-primary-header_.section ${modifiers}`} role="navigation" aria-label={ariaLabel}>
                {children}
            </nav>
        );
    }
}

export const EDS_NavSection = EDSNavSection;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a "alert"
 *
 * @category EDS Components
 */
class EDSAlert extends React.Component {
    static displayName = 'EDSAlert';
    /**
     * @type {EDSAlert}
     * @property {Boolean} [addBorder] - The boolean that determines if the component should render a css 'border'
     * @property {Boolean} [addShadow] - The boolean that determines if the component should render a css 'box-shadow'
     * @property {Boolean} [makeCompact] - The boolean that determines if there should be whitespace between the the heading and message
     * @property {Boolean} [makeGray] - The boolean that determines of the background color should be gray
     * @property {String} [message] - The content of the alert message
     * @property {Function} [onClose] - The function to call when clicking the 'close' button
     * @property {String} [type] - The type of alert, e.g., 'warning', 'error', etc.
     **/
    static propTypes = {
        addBorder: PropTypes.bool,
        addShadow: PropTypes.bool,
        makeCompact: PropTypes.bool,
        makeGray: PropTypes.bool,
        message: PropTypes.string,
        onClose: PropTypes.func,
        type: PropTypes.oneOf(['info','success','warning','error'])
    };
    
    static defaultProps = {
        type: 'info'
    };

    render() {
        const {addBorder, addShadow, makeCompact, makeGray, message, onClose, type} = this.props;
        const border = addBorder ? 'eds-alert.border' : '';
        const shadow = addShadow ? 'eds-alert.shadow' : '';
        const gray = makeGray ? 'eds-alert.gray' : '';
        const title = type.replace(/^\w/, c => c.toUpperCase());
        const headingClass = 'eds-alert_#title';
        const messageClass = 'eds-alert_#message';

        if(message) {
            return (
                <div className={`eds-alert eds-alert.${type} ${border} ${shadow} ${gray}`} role={"alert"}>
                    <button className={"eds-alert_#close eds-alert_.eds-link eds-link material-icons"}
                            aria-label={"close"} onClick={onClose}>close
                    </button>
                    <div className={"eds-alert_#content"}>
                        {makeCompact ? <span className={headingClass}>{title}: </span> : <h1 className={headingClass}>{title}</h1>}
                        {makeCompact ? <span className={messageClass}>{message}</span> : <p className={messageClass}>{message}</p>}
                    </div>
                </div>
            );
        }

        return null;
    }
}

export const EDS_Alert = EDSAlert;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a table
 *
 * @category EDS Components
 */
class EDSTableHeader extends React.Component {
    static displayName = 'EDSTableHeader';
    /**
     * @type {EDSTableHeader}
     * @property {String} [alignText] - The string to define the text-align CSS class of the element
     * @property {*} [children] - The content to render inside the table header
     **/
    static propTypes = {
        alignText: PropTypes.oneOf(['left','right']),
        children: PropTypes.any
    };

    render() {
        const {alignText = '', children} = this.props;
        const alignClass = alignText && alignText !== 'left' ? `text-${alignText}` : '';

        return (
            <th className={`eds-table_.cell eds-table_.cell.header ${alignClass}`}>
                {children}
            </th>
        );
    }
}

export const EDS_TableHeader = EDSTableHeader;
import React from 'react';

/**
 * Component responsible for rendering a Sidebar
 *
 * @category EDS Components
 */
class EDSSidebarSection extends React.Component {
    static displayName = 'EDSSidebarSection'; 

    render() {
        const { children } = this.props;
        return (
            <div className="eds-sidebar_.section">
                {children}
            </div>
        );
    }
}

export const EDS_SidebarSection = EDSSidebarSection
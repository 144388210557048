import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a checkbox
 *
 * @category EDS Components
 */
class EDSCheckbox extends React.Component {
    static displayName = 'EDSCheckbox';
    /**
     * @type {EDSCheckbox}
     * @property {Boolean} [checked] - The boolean flag that indicates whether the input is checked
     * @property {Boolean} [disabled] - The boolean flag that indicates whether the onChange event should fire
     * @property {String} [label] - The human readable label for the checkbox
     * @property {String} [modifiers] - CSS classes for modifying checkbox styling
     * @property {String} [name] - The name of the input field
     * @property {Boolean} [obscureText] - Boolean flag to pass a class to the label <span/> making it invisible to users, but not screen-readers
     * @property {Function} [onChange] - The action to be triggered when the input changes
     * @property {String} [value] - The value of the checked box
     **/
    static propTypes = {
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        modifiers: PropTypes.string,
        name: PropTypes.string,
        obscureText: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.string
    };

    render() {
        const {checked, disabled, label, modifiers = '', name, obscureText, onChange, value} = this.props;
        const onChangeAction = !disabled ? onChange : () => false;
        const screenReadOnly = obscureText ? 'sr-only' : '';

        return (
            <label className={`eds-checkbox ${modifiers}`} htmlFor={name} aria-label={label}>
                <input
                    className={'eds-checkbox_#input'}
                    type={'checkbox'}
                    checked={checked}
                    disabled={disabled}
                    name={name}
                    onChange={onChangeAction}
                    value={value}
                />
                <span className={`eds-checkbox_#label ${screenReadOnly}`}>{label}</span>
            </label>
        );
    }
}

export const EDS_Checkbox = EDSCheckbox;
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible for rendering a textarea
 *
 * @category EDS Components
 */
class EDSTextArea extends React.Component {
  static displayName = 'EDSTextArea';
  /**
   * @type {EDSTextArea}
   * @property {String} [assistiveText] - The string for adding assistive text below the input element
   * @property {Boolean} [disabled] - The boolean flag that indicates whether the onChange event should fire
   * @property {Boolean} [hasError] - The boolean flag that indicates if the input has an error
   * @property {Boolean} [hasWarning] - The boolean flag that indicates if the input has a warning
   * @property {Number} [heightModifier] - The number corresponding to preset CSS classes for setting a pixel height on the element
   * @property {String} [label] - The human readable label for the input
   * @property {String} [modifiers] - CSS classes for modifying field styling
   * @property {String} [name] - The name of the input field
   * @property {Function} [onBlur] - Event to fire when the user blurs off of the field.
   * @property {Function} [onChange] - The action to be called when the input is changed
   * @property {function} [onFocus] - Event to fire when the input field is focused
   * @property {Boolean} [readOnly] - The Boolean flag indicating whether the field should be read only
   * @property {String} [value] - The value of the textarea
   * @property {String} [placeHolder] - The placeholder text for the input
   */
  static propTypes = {
    assistiveText: PropTypes.string,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    hasWarning: PropTypes.bool,
    heightModifier: PropTypes.number,
    label: PropTypes.string,
    modifiers: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    placeHolder: PropTypes.string,
  };

  static defaultProps = {
    hasError: false,
    hasWarning: false,
    readOnly: false,
    value: undefined,
  };

  constructor(props) {
    super(props);

    this._onBlur = this._onBlur.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._renderAssistiveText = this._renderAssistiveText.bind(this);
    this._renderStateClass = this._renderStateClass.bind(this);
  }

  _onBlur() {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur();
    }
  }

  _onFocus() {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus();
    }
  }

  _renderAssistiveText() {
    const { assistiveText, hasError, hasWarning } = this.props;
    const baseClassName = 'eds-field_#assistive-text';

    return (
      <div
        className={`${baseClassName} ${
          hasError
            ? baseClassName + '.error'
            : hasWarning
            ? baseClassName + '.warning'
            : ''
        }`}
      >
        {assistiveText}
      </div>
    );
  }

  _renderStateClass() {
    const { hasError, hasWarning } = this.props;
    const baseClassName = 'eds-input';

    return hasError
      ? baseClassName + '.error'
      : hasWarning
      ? baseClassName + '.warning'
      : '';
  }

  render() {
    const {
      assistiveText,
      disabled,
      heightModifier,
      label,
      modifiers,
      name,
      onChange,
      readOnly,
      value,
      placeHolder,
    } = this.props;
    const stateInputClass = !disabled
      ? this._renderStateClass()
      : 'eds-input.disabled';
    const heightClass = heightModifier ? `h-${heightModifier}` : '';
    const onChangeAction = !readOnly ? onChange : () => false;

    return (
      <div className={`eds-field ${modifiers ? modifiers : ''}`}>
        <label
          className={`eds-field_#label ${
            disabled ? 'eds-field_#label.disabled' : ''
          }`}
          htmlFor={name}
        >
          {label}
        </label>
        <div
          className={`eds-field_#control eds-field_.eds-input eds-input ${stateInputClass} ${heightClass}`}
        >
          <textarea
            className={'eds-input_#input'}
            disabled={disabled}
            id={name}
            name={name}
            onBlur={this._onBlur}
            onChange={onChangeAction}
            onFocus={this._onFocus}
            value={value}
            placeholder={placeHolder}
          ></textarea>
        </div>
        {assistiveText && this._renderAssistiveText()}
      </div>
    );
  }
}

export const EDS_TextArea = EDSTextArea;
